var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "message-list-page" }, [
    _c(
      "div",
      { staticClass: "outer", staticStyle: { "margin-right": "10px" } },
      [
        _c("msgCenter", {
          ref: "center",
          attrs: { messageType: _vm.type },
          on: { change: _vm.activeTypes },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "outer", staticStyle: { flex: "1", overflow: "hidden" } },
      [
        _c("list-table", {
          key: _vm.type,
          ref: "table",
          attrs: { messageType: _vm.type, needPaging: _vm.needPaging },
          on: { update: _vm.update },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }