<template>
<div class="message-list-table">
  <div class="header">
    <div class="types-box">
      <span :class="{active: active === index}" v-for="(item, index) in navs" :key="index" @click="onChangeNav(item, index)">
        {{item.label}}（{{counts[item.countKey] || 0}}）
      </span>
    </div>
    <el-button type="primary" size="mini" :loading="allReadLoad" @click="onAllRead">全部已读</el-button>
  </div>

  <nb-table v-bind="tableInfo">
    <template #handles="{row}">
      <span class="span" @click="onSee(row)">查看</span>
      <i v-if="!row.isRead" class="line"></i>
      <span v-if="!row.isRead" class="span" @click="onSetRead(row)">已读</span>
    </template>
  </nb-table>
</div>
</template>

<script>
import {useMsgList} from '@/utils/pageConfigs.js';
import {localSet} from 'ldx-utils';

export default {
  props: {
    messageType: {type: Number, default: 1},
    needPaging: {type: Boolean, default: true}
  },
  data() {
    this.navs = [
      {label: '全部', countKey: 'allMessageNum', isRead: undefined},
      {label: '未读', countKey: 'notReadMessageNum', isRead: 0},
      {label: '已读', countKey: 'readMessageNum', isRead: 1}
    ];
    this.supplierId = JSON.parse(localStorage.getItem('userInfo')).suppliersId;
    useMsgList.call(this);
    return {
      // messageType: 1,
      counts: {},
      active: 0,
      isRead: undefined,
      allReadLoad: false
    };
  },
  methods: {
    onSee(row) {
      localSet('msgContent', row);
      this.$router.push({name: 'MsgDetail', query: {messageType: this.messageType}});
    },
    async onAllRead() {
      if (this.allReadLoad) return;

      const params = {messageType: this.messageType};
      this.allReadLoad = true;
      const res = await this.$axios.post(this.$api.message.allRead, params);
      if (res.code === 0) {
        this.$refs.nbTable.getData('currentChange');
        this.$emit('update');
      }
    },
    async onSetRead(row) {
      if (row.loading) return;

      const params = {
        messageId: row.id,
        messageType: this.messageType,
        supplierId: this.supplierId
      };
      row.loading = true;
      const res = await this.$axios.post(this.$api.message.read, params);
      if (res.code === 0) {
        this.$refs.nbTable.getData('currentChange');
        this.$emit('update');
      }
    },
    onChangeNav(item, index) {
      if (this.active === index) return;
      this.isRead = item.isRead;
      this.active = index;
      this.$refs.nbTable.getData();
    },
    getList(type) {
      this.messageType = type;
      this.$refs.nbTable.getData();
    },
    getData(args) {
      const params = {
        isRead: this.isRead,
        messageType: this.messageType,
        supplierId: this.supplierId,
        ...args
      };
      return new Promise(async resolve => {
        const res = await this.$axios.post(this.$api.message.message_list, params);
        const data = res.data.supplierPlatMessageInfoBoPage || {};
        if (res.code === 0) {
          this.allReadLoad = false;
          this.counts = {
            allMessageNum: res.data.allMessageNum,
            notReadMessageNum: res.data.notReadMessageNum,
            readMessageNum: res.data.readMessageNum
          };
          const list = data.records || [];
          list.forEach(item => {
            item.loading = false;
          });
          resolve({list, total: data.total});
        }
      });
    }
  }
}
</script>

<style lang="scss">
.message-list-table{
  .header{display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;}
  .types-box span{
    margin-right: 10px; cursor: pointer;
    &.active{color: $default;}
  }
  .all-box{cursor: pointer;}
  .el-table{margin-bottom: 0;}
  .no-read{padding-left: 10px; height: 10px; margin-right: 5px; border-radius: 50%;}
}
</style>