var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-list-center" },
    [
      _vm._m(0),
      _vm._l(_vm.navs, function (item) {
        return _c(
          "div",
          {
            key: item.type,
            staticClass: "items",
            class: { active: _vm.active === item.type },
            on: {
              click: function ($event) {
                return _vm.change(item)
              },
            },
          },
          [
            _c("div", { staticClass: "content" }, [_vm._v(_vm._s(item.label))]),
            _vm.counts[item.countKey]
              ? _c("div", { staticClass: "count" }, [
                  _vm._v(_vm._s(_vm.counts[item.countKey])),
                ])
              : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-box items" }, [
      _c("div", { staticClass: "dec" }, [_vm._v("消息中心")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }