var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-list-table" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c(
            "div",
            { staticClass: "types-box" },
            _vm._l(_vm.navs, function (item, index) {
              return _c(
                "span",
                {
                  key: index,
                  class: { active: _vm.active === index },
                  on: {
                    click: function ($event) {
                      return _vm.onChangeNav(item, index)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(item.label) +
                      "（" +
                      _vm._s(_vm.counts[item.countKey] || 0) +
                      "） "
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "mini",
                loading: _vm.allReadLoad,
              },
              on: { click: _vm.onAllRead },
            },
            [_vm._v("全部已读")]
          ),
        ],
        1
      ),
      _c(
        "nb-table",
        _vm._b(
          {
            scopedSlots: _vm._u([
              {
                key: "handles",
                fn: function ({ row }) {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "span",
                        on: {
                          click: function ($event) {
                            return _vm.onSee(row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    !row.isRead ? _c("i", { staticClass: "line" }) : _vm._e(),
                    !row.isRead
                      ? _c(
                          "span",
                          {
                            staticClass: "span",
                            on: {
                              click: function ($event) {
                                return _vm.onSetRead(row)
                              },
                            },
                          },
                          [_vm._v("已读")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          },
          "nb-table",
          _vm.tableInfo,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }