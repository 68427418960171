// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.message-list-center .items {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-transition: background 0.3s, color 0.3s;\n  transition: background 0.3s, color 0.3s;\n  width: 200px;\n  height: 36px;\n  padding: 3px 10px;\n  border-bottom: 1px solid #d7dae2;\n  font-size: 12px;\n  cursor: pointer;\n}\n.message-list-center .items .content {\n  -webkit-box-flex: 1;\n      -ms-flex: 1;\n          flex: 1;\n  height: 100%;\n  line-height: 29px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.message-list-center .items .count {\n  padding: 2px 5px;\n  background: red;\n  border-radius: 5px;\n  color: #fff;\n}\n.message-list-center .items.active {\n  color: #409EFF;\n}\n.message-list-center .title-box {\n  font-size: 16px;\n  padding: 3px 0;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
