// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.message-list-table .header {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: justify;\n      -ms-flex-pack: justify;\n          justify-content: space-between;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  margin-bottom: 20px;\n}\n.message-list-table .types-box span {\n  margin-right: 10px;\n  cursor: pointer;\n}\n.message-list-table .types-box span.active {\n  color: #409EFF;\n}\n.message-list-table .all-box {\n  cursor: pointer;\n}\n.message-list-table .el-table {\n  margin-bottom: 0;\n}\n.message-list-table .no-read {\n  padding-left: 10px;\n  height: 10px;\n  margin-right: 5px;\n  border-radius: 50%;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
