<template>
<div class="message-list-page">
  <div class="outer" style="margin-right: 10px;">
    <msgCenter ref="center" :messageType="type" @change="activeTypes"/>
  </div>

  <div class="outer" style="flex: 1; overflow: hidden;">
    <list-table ref="table" :key="type" :messageType="type" :needPaging="needPaging" @update="update"/>
  </div>
</div>
</template>

<script>
import msgCenter from './components/msgCenter.vue';
import listTable from './components/listTable.vue';

export default {
  components: {msgCenter, listTable},
  data() {
    const type = +this.$route.params.messageType || 1;
    return {
      type
    };
  },
  computed: {
    needPaging() {
      return [2, 3].includes(this.type)
    }
  },
  methods: {
    activeTypes(obj) {
      this.type = obj.type;
      // this.$refs.table.getList(obj.type);
    },
    update() {
      this.$refs.center.getData();
    }
  }
}
</script>

<style lang="scss">
.message-list-page{
  display: flex; width: 100%;
}
</style>