<template>
  <div class="message-list-center">
    <div class="title-box items">
      <div class="dec">消息中心</div>
    </div>

    <div class="items" :class="{active: active === item.type}" v-for="item in navs" :key="item.type" @click="change(item)">
      <div class="content">{{item.label}}</div>
      <div v-if="counts[item.countKey]" class="count">{{counts[item.countKey]}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messageType: {type: Number, default: 1},
  },
  data() {
    this.navs = [
      {label: '平台活动', countKey: 'platPromMessageNum', type: 1},
      {label: '交易物流', countKey: 'logisticsMessageNum', type: 2},
      {label: '系统通知', countKey: 'systemMessageNum', type: 3},
      {label: '平台通知', countKey: 'platFormNoticeMessageNum', type: 4}
    ];
    return {
      active: this.messageType,
      counts: {}
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    change(item) {
      if (this.active === item.type) return;
      this.active = item.type;
      this.$emit('change', item);
    },
    async getData() {
      const res = await this.$axios.get(this.$api.message.message_num);
      if (res.code === 0) {
        this.counts = res.data;
      }
    }
  }
}
</script>

<style lang="scss">
.message-list-center{
  .items{
    display: flex; align-items: center; justify-content: space-between; transition: background .3s, color .3s;
    width: 200px; height: 36px; padding: 3px 10px; border-bottom: 1px solid #d7dae2; font-size: 12px; cursor: pointer;
    .content{flex: 1; height: 100%; line-height: 29px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
    .count{padding: 2px 5px; background: red; border-radius: 5px; color: #fff;}
    &.active{color: $default;}
  }
  .title-box{font-size: 16px; padding: 3px 0;}
}
</style>